import { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ApplicationPaths } from 'src/services/ApiAuthorizationConstants';
import { useAuthenticationContext } from './authorization/AuthenticationContext';
import { mergeStyles, useTheme } from '@fluentui/react';
import { } from 'react-router-dom';

export const NavMenu = ({ home }: { home?: boolean }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { authenticated, user } = useAuthenticationContext();
  const theme = useTheme();

  const themedBg = mergeStyles(
    { backgroundColor: theme.palette.themePrimary },
    { color: theme.palette.white }
  );

  const toggleNavbar = () => {
    setCollapsed(c => !c);
  }

  return (
    <header>
      <Navbar className={`${themedBg} navbar-expand-sm navbar-toggleable-sm box-shadow ${home ? '' : 'mb-3 border-bottom'}`} container dark>
        <NavbarBrand tag={Link} to="/"><img src="/img/logo-white.png" alt="logo" style={{ maxHeight: 64, maxWidth: 192 }} /></NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row justify-content-end" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow pe-5">
            <NavItem>
              <NavLink tag={Link} to="/">Home</NavLink>
            </NavItem>
            {authenticated ?
              <>
                <NavItem>
                  <NavLink tag={Link} to="/cases">Support Requests</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/cases/create">Request IT Support</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/devices/deviceswaitingforreturn">View Devices Waiting For Return</NavLink>
                </NavItem>
              </> :
              <NavItem>
                <NavLink replace tag={Link} to={ApplicationPaths.Login} state={{ local: true }}>Login</NavLink>
              </NavItem>
            }
          </ul>
          {authenticated && <>
            <span className="pe-3">Welcome {user?.name}!</span>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink replace tag={Link} to={ApplicationPaths.LogOut} state={{ local: true }}>Logout</NavLink>
              </NavItem>
            </ul>
          </>
          }
        </Collapse>
      </Navbar>
    </header>
  );
}
