import { createContext, useContext, useEffect, useState } from 'react';
import { IPortalUser } from 'src/models/PortalUser';

interface IContext {
  ready: boolean;
  authenticated: boolean;
  user?: IPortalUser | null;
}

const AuthenticationContext = createContext<IContext>({
  ready: false,
  authenticated: false,
});

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error("useAuthenticationContext must be used within its provider.");
  }
  return context;
}

export const AuthenticationProvider = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState<IPortalUser | null>();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const user = async () => {
      try {
        const resp = await fetch("/security/user", {
          redirect: "error"
        });
        const result = await resp.json();
        setAuthenticated(result?.authenticated);
        setUser(result);
      } catch (e) {
        setAuthenticated(false);
        console.warn(e);
      } finally {
        setReady(true);
      }
    }

    user();
  }, []);

  return (
    <AuthenticationContext.Provider value={{
      ready,
      authenticated,
      user
    }}>
      {children}
    </AuthenticationContext.Provider>
  )
}