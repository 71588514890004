import { Stack } from '@fluentui/react';
import { CompoundButton } from '@fluentui/react/lib/Button';

import { ApplicationPaths } from '../services/ApiAuthorizationConstants';
import { useAuthenticationContext } from './authorization/AuthenticationContext';
import HomeLayout from './HomeLayout';

export const Home = () => {
  const { ready, authenticated } = useAuthenticationContext();
  return !ready ? <div>wait...</div> : !authenticated ? (
    <HomeLayout img="/img/scl-114.jpg">
      {/**  If the user is not authenticated, display the sign-in button */}
      <a href={ApplicationPaths.Login} style={{ textDecoration: 'none' }}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="300px" height="50px" viewBox="0 0 3278 522" className="SignInButton">
          <rect className="fil0" x="2" y="2" width="3174" height="517" fill="black" />
          <rect x="150" y="129" width="122" height="122" fill="#F35325" />
          <rect x="284" y="129" width="122" height="122" fill="#81BC06" />
          <rect x="150" y="263" width="122" height="122" fill="#05A6F0" />
          <rect x="284" y="263" width="122" height="122" fill="#FFBA08" />
          <text x="470" y="357" fill="white" className="fnt0">Sign in with Microsoft</text>
        </svg>
      </a>
    </HomeLayout>
  ) :
    (
      <HomeLayout img="/img/scl.jpg">
        <Stack.Item basis={1} shrink>
          <CompoundButton
            primary
            secondaryText="Submit a support request to IT support"
            styles={{
              textContainer: {
                textAlign: 'center'
              },
              flexContainer: {
                flexDirection: 'row-reverse'
              },
              root: {
                width: 280
              }
            }}
            href="/cases/create"
          >
            Request IT Support &gt;
          </CompoundButton>
        </Stack.Item>
        <Stack.Item basis={1} shrink>
          <CompoundButton
            primary
            secondaryText="View and manage submitted support requests"
            styles={{
              textContainer: {
                textAlign: 'center'
              },
              flexContainer: {
                flexDirection: 'row-reverse'
              },
              root: {
                width: 280
              }
            }}
            href="/cases"
          >
            View Support Requests &gt;
          </CompoundButton>
        </Stack.Item>
        <Stack.Item basis={1} shrink>
          <CompoundButton
            primary
            styles={{
              textContainer: {
                textAlign: 'center'
              },
              flexContainer: {
                flexDirection: 'row-reverse'
              },
              root: {
                width: 280
              }
            }}
            href="/devices/deviceswaitingforreturn"
          >
            View Devices Waiting For Return &gt;
          </CompoundButton>
        </Stack.Item>
      </HomeLayout>
    );
}
