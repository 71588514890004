import { useEffect, useState } from "react";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IDeviceWaitingForReturn } from "src/models/DeviceWaitingForReturn";
import { Layout } from 'src/components/Layout';

const DevicesWaitingForReturn = () => {
  dayjs.extend(utc);
  const [devicesWaitingForReturn, setDevicesWaitingForReturn] = useState<IDeviceWaitingForReturn[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getDevicesWaitingForReturn = async () => {
      try {
        const response = await fetch(`/api/device/deviceswaitingforreturn`);
        if (response.ok) {
          const result = await response.json();
          let devicesWaitingForReturn = result.map((x) => {
            return {
              name: x.name,
              serialNumber: x.serialNumber,
              registeredContact: x.registeredContact,
              manager: x.manager,
              dateAssigned: x.dateAssigned ? dayjs.utc(x.dateAssigned).format('MM-DD-YYYY') : '',
              lastActivity: x.lastActivity ? dayjs.utc(x.lastActivity).format('MM-DD-YYYY h:mm A') : '',
              daysWaitingForEquipment: x.daysWaitingForEquipment
            } as IDeviceWaitingForReturn
          })
          setDevicesWaitingForReturn(devicesWaitingForReturn);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    getDevicesWaitingForReturn();
  }, []);
  const columns: IColumn[] = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      data: "string",
      minWidth: 200,
      maxWidth: 250,
      isPadded: true,
      isResizable: true,
    },
    {
      key: "serialNumber",
      name: "Serial Number",
      fieldName: "serialNumber",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "registeredContact",
      name: "Registered Contact",
      fieldName: "registeredContact",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "manager",
      name: "Manager",
      fieldName: "manager",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "dateAssigned",
      name: "Date Assigned",
      fieldName: "dateAssigned",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "lastActivity",
      name: "Last Activity",
      fieldName: "lastActivity",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "daysWaitingForEquipment",
      name: "Days Waiting For Equipment",
      fieldName: "daysWaitingForEquipment",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: "number",
      isPadded: true,
    }
  ];

  return (
    <Layout>
      <h1>Devices Waiting For Return</h1>
      <ShimmeredDetailsList
        enableShimmer={loading}
        items={devicesWaitingForReturn}
        columns={columns}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        disableResizeObserver={false}
      />
    </Layout>
  );
};

export default DevicesWaitingForReturn;
