import {Route, Routes} from 'react-router-dom';
import {ThemeProvider, createTheme} from '@fluentui/react/lib/Theme';
import {initializeIcons} from '@fluentui/react';
// Components
import AppRoutes from './AppRoutes';
import {AuthenticationProvider} from './components/authorization/AuthenticationContext';
import {AuthorizeRoute} from './components/authorization/AuthorizeRoute';
import './custom.css';
import Cases from './pages/cases';
import CreateCase from './pages/cases/CreateCase';
import DevicesWaitingForReturn from './pages/devices/DevicesWaitingForReturn';
import Case from "./pages/case/[ticketNumber]";

const AppRequireAuth = process.env.REACT_APP_REQUIRE_AUTH === 'true';
initializeIcons();
const appTheme = createTheme({
	palette: {
		themePrimary: "rgb(0, 53, 142)"
	},
	fonts: {
		mediumPlus: {
			fontWeight: 600
		}
	}
});

const App = () => (
	<ThemeProvider theme={appTheme}>
		<AuthenticationProvider>
			<Routes>
				{AppRoutes.map((route, index) => {
					const {element, requireAuth, ...rest} = route;
					return <Route key={index} {...rest} element={AppRequireAuth && requireAuth ?
						<AuthorizeRoute {...rest} element={element}/> : element}/>;
				})}
				<Route
					path="/cases/create"
					element={<AuthorizeRoute path="/cases/create" element={<CreateCase/>}/>}
				/>
				<Route
					path="/cases"
					element={<AuthorizeRoute path="/cases" element={<Cases/>}/>}
				/>
				<Route
					path="/devices/deviceswaitingforreturn"
					element={<AuthorizeRoute path="/devices/deviceswaitingforreturn" element={<DevicesWaitingForReturn/>}/>}
				/>
				<Route
					path="/case/*"
					element={<AuthorizeRoute path="/cases/:ticketNumber" element={<Case/>}/>}
				/>
				<Route>
					Fail
				</Route>
			</Routes>
		</AuthenticationProvider>
	</ThemeProvider>
);

export default App;