import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) =>
  <div>
    <NavMenu />
    <Container>
      {children}
    </Container>
  </div>
