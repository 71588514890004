import { useEffect, useState } from 'react'
import { QueryParameterNames, LogoutActions, ApplicationPaths } from '../../services/ApiAuthorizationConstants';

export const Logout = ({ action }) => {
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    switch (action) {
      case LogoutActions.Logout:
        if (!!window.history.state.usr.local) {
          logout(getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          setMessage("The logout was not initiated from within the page.");
        }
        break;
      case LogoutActions.LogoutCallback:
        logout(getReturnUrl());
        break;
      case LogoutActions.LoggedOut:
        setMessage("You successfully logged out!");
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const logout = async (returnUrl) => {
    if (action === LogoutActions.LogoutCallback) {
      navigateToReturnUrl(returnUrl);
    } else if (action === LogoutActions.Logout) {
      window.location.assign(`/security/logout?returnUrl=${returnUrl}`);
    }
  }

  const getReturnUrl = (state?) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
    }
    return (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.LoggedOut;
  }

  const navigateToReturnUrl = (returnUrl) => {
    return window.location.replace(returnUrl);
  }

  if (!!message) {
    return (<div>{message}</div>);
  } else {
    switch (action) {
      case LogoutActions.Logout:
        return (<div>Processing logout</div>);
      case LogoutActions.LogoutCallback:
        return (<div>Processing logout callback</div>);
      case LogoutActions.LoggedOut:
        setTimeout(() => {
          window.location.assign("/");
        }, 1000);
        return (<div>{message}</div>);
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }
}
