import {Separator, Stack, Text} from "@fluentui/react";
import {useState} from "react";
import {IEmail} from "src/models/Emails";

const replaceDescription = (description: string) => description?.replace(/<br>/gs, "\n").replace(/------------------- Original Message.*|src=.*>|<[^>]*>|&nbsp;/gs, "").trim()

export const EmailItem: React.FC<{
	email: IEmail;
	initMinimized: boolean;
}> = ({email, initMinimized}) => {
	const [minimized, toggleMinimized] = useState(initMinimized);

	return minimized ? (
		<>
			<button
				onClick={() => toggleMinimized(false)}
				className="w-100 border-0 text-start py-2 px-0 bg-transparent"
			>
				<Stack horizontal>
					<Stack.Item grow>
						<Text block className="mb-2 fw-bold" variant="medium">
							{email.sender}
						</Text>
					</Stack.Item>

					<Stack.Item>
						<Text block className="mb-2" variant="small">
							{new Date(email.sentDate).toLocaleString()}
						</Text>
					</Stack.Item>
				</Stack>

				<Text block className="text-truncate">
					{replaceDescription(email.description)}
				</Text>
			</button>

			<Separator/>
		</>
	) : (
		<button
			onClick={() => toggleMinimized(true)}
			className="w-100 border-0 text-start p-0 bg-transparent"
		>
			<Stack horizontal>
				<Stack.Item grow>
					<Text block className="mb-2 fw-bold" variant="medium">
						<span className="text-secondary">From:</span> {email.sender}
					</Text>
				</Stack.Item>

				<Stack.Item>
					<Text block className="mb-2" variant="small">
						{new Date(email.sentDate).toLocaleString()}
					</Text>
				</Stack.Item>
			</Stack>

			<Text block className="mb-2" variant="medium">
				<span className="text-secondary">To:</span> {email.recipient.split(';').filter(rec => rec !== '').join(', ')}
			</Text>

			{email.ccRecipient &&
				<Text block className="mb-2" variant="medium">
					<span
						className="text-secondary">CC:</span> {email.ccRecipient.split(';').filter(rec => rec !== '').join(', ')}
				</Text>
			}

			<Text block className="mb-2" variant="medium">
				<span className="text-secondary">Subject:</span> {email.subject}
			</Text>

			<Text block className="text-secondary mb-2" variant="medium">
				Message:
			</Text>

			<Text block className="mb-2" variant="medium" style={{ whiteSpace: "pre-line" }}>
				{replaceDescription(email.description)}
			</Text>

			{initMinimized && <Separator/>}
		</button>
	);
};
