import React from 'react';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';

import { NavMenu } from './NavMenu';

const HomeLayout = ({ children, img }: { children: React.ReactElement[] | React.ReactElement, img: string }) => (
  <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
    <NavMenu home />
    <Image className="mb--3" src={img} alt="home image" imageFit={ImageFit.cover} style={{ opacity: .3 }} />
    <div style={{ position: 'absolute', width: '100%', top: '50%' }}>
      <Stack horizontal horizontalAlign="center">
        <div style={{ textAlign: 'center' }}>
          <div>
            <Text variant="mega" style={{ lineHeight: 1 }}>Stake Center</Text>
          </div>
          <div>
            <Text variant="xxLarge">User Support Request Management</Text>
          </div>
          <Stack horizontal horizontalAlign="center" wrap styles={{ root: { paddingTop: 25, marginRight: 25, marginLeft: 25 } }} tokens={{ childrenGap: 15 }}>
            {children}
          </Stack>
        </div>
      </Stack>
    </div >
  </div >
)

export default HomeLayout;