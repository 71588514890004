import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from '../../services/ApiAuthorizationConstants'
import { useAuthenticationContext } from './AuthenticationContext'

export const AuthorizeRoute = ({ element, path }: { element: JSX.Element, path: string }) => {
  const { ready, authenticated } = useAuthenticationContext();

  var link = document.createElement("a");
  link.href = path;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
  if (!ready) {
    return <></>;
  } else {
    return authenticated ? element : <Navigate replace to={redirectUrl} />;
  }
}
