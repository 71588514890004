import { useState, useEffect } from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import {
  ComboBox,
  IComboBoxStyles,
  IComboBoxOption,
} from "@fluentui/react/lib/ComboBox";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Stack, IStackProps, IStackStyles } from "@fluentui/react/lib/Stack";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { Text } from "@fluentui/react/lib/Text";
import { Callout } from '@fluentui/react/lib/Callout';
import { DelayedRender } from '@fluentui/react/lib/Utilities';
import { Link } from '@fluentui/react/lib/Link';

import { ICaseType } from "src/models/Case";

const stackTokens = { childrenGap: 50 };
const stackStyles: Partial<IStackStyles> = { root: { width: "50%" } };
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { width: "50%" } };

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 25 },
  styles: { root: { width: "90%" } },
};

function copyTextToClipboard(text, callback) {
  if (!navigator.clipboard) {
    // clipboard api not present
    return;
  }
  navigator.clipboard.writeText(text).then(callback, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export const CreateCaseForm = () => {
  //lookup data
  const [types, setTypes] = useState<IComboBoxOption[]>([]);

  // fields
  const [caseTitle, setCaseTitle] = useState("");
  const [typeSelected, setTypeSelected] = useState(
    {} as IComboBoxOption | null
  );
  const [description, setDescription] = useState("");
  const [attachments, setAttachments] = useState<FileList | null>();
  const [caseID, setCaseID] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const copyLinkId = useId("copy-link");

  useEffect(() => {
    async function getTypes() {
      const resp = await fetch("api/case/types", {
        redirect: "error",
      });
      const result = (await resp.json()) as ICaseType[];

      setTypes(
        result.map((x) => {
          return {
            key: x.id,
            text: x.name,
          } as IComboBoxOption;
        })
      );
    }

    getTypes();
  }, []);

  const submitForm = async () => {
    let formData = new FormData();
    formData.append("CaseTitle", caseTitle);
    formData.append("CaseTypeId", typeSelected!.key as string);
    formData.append("Description", description);
    if (attachments?.length) {
      for (let i = 0; i < attachments.length; i++) {
        let attachmentRecord = attachments[i];
        formData.append(`Files`, attachmentRecord, attachmentRecord.name);
      }
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      setLoading(true);
      const reponse = await (await fetch("api/case", requestOptions)).json();
      setCaseID(reponse.ticketNumber);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (e: any, comboxBox: IComboBoxOption | undefined) => {
    if (comboxBox) {
      setTypeSelected({ key: comboxBox.key, text: comboxBox.text });
    } else {
      setTypeSelected(null);
    }
  };

  const formDisabled = !!caseID || loading;

  return (
    <>
      <Stack horizontal tokens={stackTokens} styles={stackStyles}>
        <Stack {...columnProps}>
          <TextField
            label="Subject"
            required
            disabled={formDisabled}
            maxLength={200}
            onChange={(e: any) => setCaseTitle(e.target.value)}
          />
          <ComboBox
            required
            label="Type"
            disabled={formDisabled}
            options={types}
            styles={comboBoxStyles}
            onChange={handleSelect}
          />
          <TextField
            label="Description"
            required
            disabled={formDisabled}
            maxLength={1000000}
            multiline
            onChange={(e: any) => setDescription(e.target.value)}
          />
          <input
            type="file"
            disabled={formDisabled}
            onChange={(e) => setAttachments(e.target.files)}
            multiple
          />
          {/* If the required fields are selected, set disabled and disabled text. Once the case is created and the Case ID (ticketnumber) is returned, hide button */}
          {!caseID && (
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
              <Stack.Item>
                <PrimaryButton
                  text={
                    !caseTitle || !typeSelected?.key || !description
                      ? "Enter required fields..."
                      : "Save Support Request"
                  }
                  disabled={
                    !caseTitle || !typeSelected?.key || !description || loading
                  }
                  onClick={submitForm}
                />
              </Stack.Item>
              {loading && (
                <Stack.Item>
                  <Spinner />
                </Stack.Item>
              )}
            </Stack>
          )}
          {/* Once the case has been saved and the ticket number is returned, display the Case ID (ticketnumber).*/}
          {caseID && (
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
              <Stack {...columnProps}>
                <Stack.Item>
                  <Text variant="medium">
                    Your ticket{" "}<Text id={copyLinkId} variant="mediumPlus" as="a" onClick={() => copyTextToClipboard(caseID, toggleIsCalloutVisible)}>{caseID}</Text>{" "}has been received by the service desk. Someone will be with you to help resolve the problem shortly.
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  <Text variant="medium">
                    Please be sure to include this ticket ID in your communication with the Service Desk Team.
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  <Text variant="mediumPlus">
                    <Link href="/cases"> View All Support Requests</Link>
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  <DefaultButton href="/cases/create" iconProps={{ iconName: "Add" }}>Add New</DefaultButton>
                </Stack.Item>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {isCalloutVisible && (
        <Callout style={{ padding: '10px 12px' }} target={`#${copyLinkId}`} onDismiss={toggleIsCalloutVisible} role="alert">
          <DelayedRender>
            <Text variant="small">
              Ticket ID copied!
            </Text>
          </DelayedRender>
        </Callout>
      )}
    </>
  );
};
