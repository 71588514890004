import { useEffect, useState } from "react";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { CommandBar } from '@fluentui/react';
import { Link } from 'react-router-dom'

import { Layout } from 'src/components/Layout';
import { ICase } from "src/models/Case";

const Cases = () => {
  const [cases, setCases] = useState<ICase[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getCases = async () => {
      try {
        const response = await fetch("/api/case/cases");
        if (response.ok) {
          const result = await response.json();
          setCases(result);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    getCases();
  }, []);
  const columns: IColumn[] = [
    {
      key: "title",
      name: "Subject",
      fieldName: "title",
      data: "string",
      minWidth: 150,
      maxWidth: 200,
      isPadded: true,
      isResizable: true,
    },
    {
      key: "ticketNumber",
      name: "ID",
      fieldName: "ticketNumber",
      minWidth: 50,
      maxWidth: 100,
      data: "string",
      isPadded: true,
      isResizable: true,
      onRender: (item: ICase) => (
        <Link to={`/case/${item.ticketNumber}`} state={item}>
          {item.ticketNumber}
        </Link>
      )
    },
    {
      key: "caseType",
      name: "Type",
      fieldName: "caseType",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "descripton",
      name: "Description",
      fieldName: "descripton",
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "assignedAgent",
      name: "Assigned Agent",
      fieldName: "assignedAgent",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },{
      key: "escalationResolution",
      name: "Resolution",
      fieldName: "escalationResolution",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      isPadded: true,
    },
  ];

  return (
    <Layout>
      <h1>Support Requests</h1>
      <h6>
        Please use your email to follow up on requests using the original notification email that was provided upon the request creation.
      </h6>
      <CommandBar items={[]} farItems={[{
        title: "Create New Request",
        text: "Create New Request",
        iconOnly: false,
        iconProps: {
          iconName: "Add"
        },
        key: "add",
        href: "/cases/create"
      }]} />
      <ShimmeredDetailsList
        items={cases}
        columns={columns}
        enableShimmer={loading}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        disableResizeObserver={false}
      />
    </Layout>
  );
};

export default Cases;
