import ApiAuthorizationRoutes from './components/authorization/ApiAuthorizationRoutes';
import { Home } from "./components/Home";

const AppRoutes: { requireAuth?: boolean, path: string, element: JSX.Element }[] = [
  {
    requireAuth: true,
    path: "/",
    element: <Home />
  },
  ...ApiAuthorizationRoutes
];

export default AppRoutes;
