import {useEffect, useState} from "react";
import {Navigate, useLocation, Location} from "react-router-dom";
import {
	CommandBarButton,
	PrimaryButton,
	Separator,
	Spinner,
	SpinnerSize,
	Stack,
	Text,
	TextField,
} from "@fluentui/react";

import {ICase} from "src/models/Case";
import {Layout} from "src/components/Layout";
import {IEmail} from "../../models/Emails";
import {EmailItem} from "src/components/CaseEmail/EmailItem";

export default function Case() {
	const {state} = useLocation() as Location as { state: ICase };
	const [emails, setEmails] = useState<IEmail[]>([]);
	const [reply, setReply] = useState("");
	const [emailPending, toggleEmailPending] = useState(false);

	const getEmails = async () => {
		try {
			const response = await fetch(`api/case/emails?caseId=${state.id}`, {});
			if (response.ok) {
				const result = await response.json();
				setEmails(
					result.emails.sort(
						(a: IEmail, b: IEmail) =>
							new Date(a.sentDate).getTime() - new Date(b.sentDate).getTime()
					)
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		getEmails();
	}, []);

	const handleReplyChange = (
		e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
	) => setReply(e.currentTarget.value);

	const handleSendReply = async () => {
		if (state.id) {
			toggleEmailPending(true)
			let formData = new FormData();
			formData.append("Recipients", emails[emails.length - 1].recipient);
			formData.append("Subject", emails[emails.length - 1].subject);
			formData.append("Body", reply);
			formData.append("CaseId", state.id);

			try {
				const response = await fetch("api/case/sendemail", {
					method: "POST",
					body: formData,
				});

				if (response.ok) {
					await getEmails();
					setReply("");
					toggleEmailPending(false)
				}
			} catch (e) {
				console.error(e);
			}
		}
	};

	return !state ? (
		<Navigate to="/cases"/>
	) : (
		<Layout>
			<Stack horizontal>
				<Stack.Item grow>
					<Text block className="pb-3" variant="large">
						<span className="text-secondary">Case:</span> {state.ticketNumber}
					</Text>
				</Stack.Item>

				<Stack.Item grow>
					<Text block className="pb-3" variant="large">
						<span className="text-secondary">Title:</span> {state.title}
					</Text>
				</Stack.Item>

				<Stack.Item grow>
					<Text block className="pb-3" variant="large">
						<span className="text-secondary">Type:</span> {state.caseType}
					</Text>
				</Stack.Item>

				<Stack.Item grow>
					<Text block className="pb-3 text-truncate" variant="large" style={{maxWidth: "36ch"}}>
						<span className="text-secondary">Description:</span>{" "}
						{state.descripton}
					</Text>
				</Stack.Item>
			</Stack>

			<Separator/>

			<>
				{emails.filter(email => email.description !== null && email.recipient !== null).map((email, index) => (
					<EmailItem
						key={email.emailId}
						email={email}
						initMinimized={index !== emails.length - 1}
					/>
				))}
			</>

			{state.status !== "ProblemSolved" &&
			state.status !== "Cancelled" &&
			state.status !== "Merged" ? (
				<>
					<TextField
						value={reply}
						onChange={handleReplyChange}
						className="my-3"
						label="Reply"
						multiline
						resizable={false}
					/>

					<PrimaryButton disabled={emailPending} onClick={handleSendReply} className="mb-4">
						{emailPending ? <Spinner size={SpinnerSize.small}/> : "Send"}
					</PrimaryButton>
				</>
			) : (
				<>
					<Separator/>


					<Text block className="fw-bold" variant="medium">
						This case is closed. The resolution is: {state.escalationResolution}. If you need assistance with this same issue, please create a new ticket
					</Text>

					<Stack horizontal styles={{ root: { height: 44 } }} tokens={{ childrenGap: 20 }}>
						<CommandBarButton text="New Support Request" iconProps={{iconName: "Add"}} href={"/cases/create"} />
					</Stack>
				</>
			)}

		</Layout>
	);
}
