import { useEffect, useState } from 'react';
import { LoginActions, QueryParameterNames } from '../../services/ApiAuthorizationConstants';

export const Login = ({ action }) => {
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    switch (action) {
      case LoginActions.Login:
        login(getReturnUrl());
        break;
      case LoginActions.LoginCallback:
        login(getReturnUrl());
        break;
      case LoginActions.LoginFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        setMessage(error);
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const login = async (returnUrl) => {
    if (action === LoginActions.LoginCallback) {
      navigateToReturnUrl(returnUrl);
    } else if (action === LoginActions.Login) {
      window.location.assign(`/security/logon?returnUrl=${returnUrl}`);
    }
  }

  const getReturnUrl = (state?) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
    }
    return (state && state.returnUrl) || fromQuery || "/";
  }

  const navigateToReturnUrl = (returnUrl) => {
    window.location.replace(returnUrl);
  }

  if (!!message) {
    return <div>{message}</div>
  } else {
    switch (action) {
      case LoginActions.Login:
        return (<div>Processing login</div>);
      case LoginActions.LoginCallback:
        return (<div>Processing login callback</div>);
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }
}
