import { CreateCaseForm } from "src/components/CaseCreate/CreateCaseForm";
import { Layout } from 'src/components/Layout';


const CreateCase = () => (
  <Layout>
    <h1>Request IT Support</h1>
    <CreateCaseForm />
  </Layout>
);

export default CreateCase;